<template lang="pug">
include ../pug/svg
section#view
	h1 ManageLearningCourseFeedback
</template>

<script>
export default {
	name: 'ManageLearningCourseFeedback',
}
</script>

<style lang="scss" scoped>
</style>
